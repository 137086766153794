import { type AxiosError, type AxiosRequestConfig } from 'axios';

import { type IResponseWithCode } from '@gbs-monorepo-packages/auth';

import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
  type IResponseDefaultContent,
  api,
} from './api';
import { type IClientFileDTO, type IDocumentDTO } from './documents';

export interface IResponseCompanyDTO extends IResponseDefaultContent {
  company: ICompanyDTO;
}
export type IResponseCompanyWithCodeDTO = IResponseCompanyDTO &
  IResponseWithCode;

export interface ICompanyDTO {
  id: number;
  employeeCount: number;
  industry?: string;
  logoUrl: string;
  name: string;
  state: string;
  websiteUrl: string;
  managerId?: string;
  consultantId?: string;
  logoImage: IDocumentDTO | null;
  clientFile?: IClientFileDTO;
  updatedAt: string;
  defaultLmsFolder: {
    id: number;
    name: string;
  };
}

export type IAddCompanyDTO = Omit<
  ICompanyDTO,
  'defaultLmsFolder' | 'id' | 'logoImage' | 'logoUrl' | 'updatedAt'
> & {
  logoImage?: File;
};

export type IDeleteCompany = Pick<ICompanyDTO, 'id'>;

export type IUpdateCompanyDTO = Partial<
  Omit<ICompanyDTO, 'defaultLmsFolder' | 'id' | 'logoImage' | 'logoUrl'>
> &
  Pick<ICompanyDTO, 'id'> & {
    logoImage?: File;
    updatedAt: string;
  };

export interface IPaginationCompaniesDTO {
  data: ICompanyDTO[];
  meta: IPaginationMetaProps;
}
export interface IGetCompaniesProps {
  page: number;
  limit: number;
  filter: string;
  all?: boolean;
}

export const getCompanies = async (
  { page, limit, filter, all = false }: IGetCompaniesProps,
  config?: AxiosRequestConfig
): Promise<IPaginationCompaniesDTO> => {
  const result = await api
    .get<IPaginationResponseData<ICompanyDTO[]>>(
      `api/companies/?page=${all ? 1 : page}&limit=${
        all ? 9999 : limit
      }&filter=${all ? '' : encodeURIComponent(filter)}`,
      config
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export type IGetClientByIdDTO = Pick<ICompanyDTO, 'id'>;

export const getClientById = async ({
  id,
}: IGetClientByIdDTO): Promise<ICompanyDTO> => {
  const result = await api
    .get<IResponseData<ICompanyDTO>>(`api/companies/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const addCompany = async ({
  name,
  employeeCount,
  websiteUrl,
  industry,
  logoImage,
  state,
  managerId,
  consultantId,
}: IAddCompanyDTO): Promise<ICompanyDTO> => {
  const data = new FormData();

  websiteUrl && data.append('websiteUrl', websiteUrl);
  name && data.append('name', name);
  employeeCount > 0 && data.append('employeeCount', String(employeeCount));
  industry && data.append('industry', industry);
  logoImage && data.append('logo', logoImage, logoImage.name);
  state && data.append('state', state);
  managerId !== undefined && data.append('managerId', String(managerId));
  consultantId !== undefined &&
    data.append('consultantId', String(consultantId));

  const result = await api
    .post<IResponseData<ICompanyDTO>>('api/companies/', data)
    .then((resp) => resp.data.data)
    .catch((err: AxiosError<IResponseWithCode>) => {
      throw err;
    });

  return result;
};

export const deleteCompany = async (
  id: number
): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`api/companies/${id}`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const updateCompany = async ({
  id,
  employeeCount,
  industry,
  logoImage,
  name,
  state,
  websiteUrl,
  managerId,
  consultantId,
  updatedAt,
}: IUpdateCompanyDTO): Promise<ICompanyDTO> => {
  const data = new FormData();

  employeeCount !== undefined &&
    data.append('employeeCount', employeeCount.toString());
  industry !== undefined && data.append('industry', industry);
  logoImage && data.append('logo', logoImage, logoImage.name);
  name !== undefined && data.append('name', name);
  state !== undefined && data.append('state', state);
  websiteUrl !== undefined && data.append('websiteUrl', websiteUrl);
  managerId && data.append('managerId', managerId);
  consultantId && data.append('consultantId', consultantId);
  data.append('updatedAt', updatedAt);

  const result = await api
    .post<IResponseData<ICompanyDTO>>(`api/companies/${id}?_METHOD=PATCH`, data)
    .then((resp) => resp.data.data)
    .catch((err: AxiosError<IResponseWithCode>) => {
      throw err;
    });

  return result;
};

export const deleteLogoImage = async (
  companyId: number
): Promise<ICompanyDTO> => {
  const result = await api
    .delete<IResponseData<ICompanyDTO>>(`api/companies/logoImage/${companyId}`)
    .then((resp) => resp.data.data)
    .catch((err: AxiosError<IResponseWithCode>) => {
      throw err;
    });

  return result;
};
