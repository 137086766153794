import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  BaseTooltip,
  Button,
  FormBase,
  LazyImage,
  SelectData,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { SelectContainer } from '../../components/SelectContainer';
import { SelectManagers } from '../../components/SelectManagers';
import { UploadField } from '../../components/UploadField';
import { Industries } from '../../constants/Industries';
import { States } from '../../constants/States';
import { useCompanies } from '../../hooks/useCompanies';
import Logger from '../../utils/logger';
import { type UpdateCompanySchema, updateCompanySchema } from './companySchema';
import {
  ButtonContainer,
  DeleteButton,
  DeleteIcon,
  Header,
  ImageContainer,
  ImageSection,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  RollbackIcon,
  TitlePage,
} from './styles';

export const CompanySettings = (): JSX.Element => {
  const { selectedCompany, updateCompany, deleteLogoImage } = useCompanies();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [changeImage, setChangeImage] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectDataIndustry = useRef(null);
  const selectDataState = useRef(null);

  const companyHasLogo =
    selectedCompany?.clientFile?.path ?? selectedCompany?.logoImage?.path;

  const createClient = useForm<UpdateCompanySchema>({
    resolver: zodResolver(updateCompanySchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    trigger,
  } = createClient;

  const values = watch();
  fileInputRef.current && (fileInputRef.current.value = '');
  useEffect(() => {
    setValue('logoImage', changeImage);
  }, [changeImage, setValue]);

  const handleConsultantIdSelectChange = (value: string) => {
    setValue('consultantId', value);
    void trigger('consultantId');
  };

  const handleIndustrySelectChange = (value: string) => {
    setValue('industry', value);
  };

  const handleManagerIdSelectChange = (value: string) => {
    setValue('managerId', value);
    void trigger('managerId');
  };

  const handleStateSelectChange = (value: string) => {
    setValue('state', value);
  };

  useEffect(() => {
    const asyncForm = () => {
      if (selectedCompany) {
        let urlDocument = '';

        if (selectedCompany.clientFile?.path) {
          urlDocument = selectedCompany.clientFile.path;
        } else if (selectedCompany.logoImage?.path) {
          urlDocument = selectedCompany.logoImage.path;
        }
        setValue('id', selectedCompany.id);
        setValue('name', selectedCompany.name);
        setValue('employeeCount', selectedCompany.employeeCount);
        setValue('websiteUrl', selectedCompany.websiteUrl);
        setValue('state', selectedCompany.state);
        setValue('industry', selectedCompany.industry);
        setValue(
          'managerId',
          selectedCompany.managerId ? selectedCompany.managerId.toString() : ''
        );
        setValue(
          'consultantId',
          selectedCompany.consultantId
            ? selectedCompany.consultantId.toString()
            : ''
        );
        setValue('updatedAt', selectedCompany.updatedAt);
        setImageSrc(urlDocument);
      }
    };
    asyncForm();
  }, [selectedCompany, setValue]);

  const handleCancelUpload = useCallback(() => {
    setChangeImage(null);
    setImageSrc(selectedCompany?.clientFile?.path ?? '');
    fileInputRef.current && (fileInputRef.current.value = '');
  }, [selectedCompany]);

  const handleChangeImage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.files?.length === 0) {
        handleCancelUpload();
      }

      const newFile = target.files?.item(0);
      if (newFile) {
        setChangeImage(newFile);
        setImageSrc(URL.createObjectURL(newFile));
      }
    },
    [handleCancelUpload]
  );

  const handleDeleteImage = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (selectedCompany) {
        setLoading(true);

        deleteLogoImage(selectedCompany.id)
          .then(() => {
            setImageSrc('');
            setLoading(false);
            addToast({
              title: 'Image deleted',
              description: `Logo image successfully deleted.`,
              styleType: 'success',
              dataCy: 'client-delete-image-success-toast',
            });
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              title: 'Error on delete image',
              description: `An error occurred. Please try again or contact Benefit Education support.`,
              styleType: 'error',
              dataCy: 'client-delete-image-error-toast',
            });
            Logger.debug('err on delete logo image: ', err);
          });
      }
    },
    [deleteLogoImage, selectedCompany, addToast]
  );

  const onSubmit: SubmitHandler<UpdateCompanySchema> = (
    data: UpdateCompanySchema
  ) => {
    if (loading) return;

    setLoading(true);

    updateCompany(data)
      .then(() => {
        setChangeImage(null);

        fileInputRef.current && (fileInputRef.current.value = '');

        addToast({
          title: 'Client updated',
          description: `Client information successfully updated`,
          styleType: 'success',
          dataCy: 'company-update-success-toast',
        });
      })
      .catch((err) => {
        Logger.debug('err updated client: ', err);

        addToast({
          title: 'Error on updating client',
          description:
            'An error occurred. Please try again or contact Benefit Education support.',
          styleType: 'error',
          dataCy: 'company-update-error-toast',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Client Settings</TitlePage>
      </Header>
      <MainContent data-cy="company-settings-form-container">
        {selectedCompany === null ? (
          <LoadingContainer data-cy="loading-courses-container">
            <Loading dataCy="loading-courses" />
          </LoadingContainer>
        ) : (
          <FormBase.Provider {...createClient}>
            <FormBase.Root
              data-cy="company-settings-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormBase.Content>
                <FormBase.InputContent
                  dataCy="label-Client Name"
                  errorMessage={errors.name?.message}
                  filled={!!values?.name}
                  inputRef="name"
                  label="Client Name"
                >
                  <FormBase.InputText
                    dataCy="input-name"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name ?? ''}
                  />
                </FormBase.InputContent>

                <ImageSection data-cy="image-section">
                  {companyHasLogo && !changeImage && (
                    <BaseTooltip message="Delete image">
                      <DeleteButton
                        data-cy="button-deleteImage"
                        disabled={loading}
                        onClick={handleDeleteImage}
                      >
                        <DeleteIcon />
                      </DeleteButton>
                    </BaseTooltip>
                  )}
                  <ImageContainer data-cy="client-imageContainer">
                    {changeImage && (
                      <DeleteButton
                        data-cy="button-removeImage"
                        onClick={handleCancelUpload}
                      >
                        <RollbackIcon />
                      </DeleteButton>
                    )}
                    <LazyImage
                      alt="client-image"
                      dataCy="client-image"
                      src={imageSrc}
                    />
                  </ImageContainer>
                  <UploadField
                    accept="image/*"
                    data-cy="input-logo"
                    label="Logo"
                    onChange={handleChangeImage}
                    ref={fileInputRef}
                  />
                </ImageSection>
                <FormBase.InputContent
                  dataCy="label-# of employees"
                  errorMessage={errors.employeeCount?.message}
                  filled={!!values?.employeeCount}
                  inputRef="employees"
                  label="# of employees"
                >
                  <FormBase.InputText
                    dataCy="input-employees"
                    id="employees"
                    max={999999}
                    min={1}
                    name="employeeCount"
                    type="number"
                    value={values.employeeCount ?? ''}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-Website URL"
                  errorMessage={errors.websiteUrl?.message}
                  filled={!!values?.websiteUrl}
                  inputRef="websiteUrl"
                  label="Website URL"
                >
                  <FormBase.InputText
                    dataCy="input-website"
                    id="websiteUrl"
                    name="websiteUrl"
                    type="text"
                    value={values.websiteUrl ?? ''}
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-State"
                  errorMessage={errors.state?.message}
                  filled={!!values.state}
                  inputRef="select-state"
                  label="State"
                  value={values.state}
                  isSelectData
                >
                  <SelectData
                    data={States}
                    dataCy="button-select-state"
                    onValueChange={handleStateSelectChange}
                    ref={selectDataState}
                    value={values.state}
                    zIndex={2}
                    placeholder="State"
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  dataCy="label-Industry"
                  errorMessage={errors.industry?.message}
                  filled={!!values.industry}
                  inputRef="select-state"
                  label="Industry"
                  value={values.industry}
                  isSelectData
                >
                  <SelectData
                    data={Industries}
                    dataCy="button-select-industry"
                    onValueChange={handleIndustrySelectChange}
                    ref={selectDataIndustry}
                    value={values.industry}
                    zIndex={2}
                    placeholder="Industry"
                  />
                </FormBase.InputContent>
                <FormBase.InputContent
                  filled={!!values.managerId}
                  label="Manager"
                  errorMessage={errors.managerId?.message}
                  dataCy="label-managerId"
                  isSelectData
                >
                  <SelectContainer>
                    <SelectManagers
                      onValueChange={handleManagerIdSelectChange}
                      dataCy="select-managerId"
                      defaultValue={values.managerId}
                    />
                  </SelectContainer>
                </FormBase.InputContent>
                <FormBase.InputContent
                  filled={!!values.consultantId}
                  label="Consultant/Broker"
                  errorMessage={errors.consultantId?.message}
                  dataCy="label-consultantId"
                  isSelectData
                >
                  <SelectContainer>
                    <SelectManagers
                      onValueChange={handleConsultantIdSelectChange}
                      dataCy="select-consultantId"
                      defaultValue={values.consultantId}
                    />
                  </SelectContainer>
                </FormBase.InputContent>
              </FormBase.Content>
              <FormBase.Content>
                <ButtonContainer>
                  <Button
                    dataCy="button-update"
                    type="submit"
                    loading={loading}
                  >
                    Update Client
                  </Button>
                </ButtonContainer>
              </FormBase.Content>
            </FormBase.Root>
          </FormBase.Provider>
        )}
      </MainContent>
    </MainContainer>
  );
};
