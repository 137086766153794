import {
  type ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  FormBase,
  FormModal,
  LazyImage,
  SelectData,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { Industries } from '../../constants/Industries';
import { States } from '../../constants/States';
import {
  type InsertCompanySchema,
  insertCompanySchema,
} from '../../pages/CompanySettings/companySchema';
import { SelectContainer } from '../SelectContainer';
import { SelectManagers } from '../SelectManagers';
import {
  DeleteButton,
  DeleteIcon,
  ImageContainer,
  ImageSection,
  UploadFieldCustom,
} from './styles';

const states = States;
const industries = Industries;

interface IAddCompanyProps {
  onAccept: (data: InsertCompanySchema) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
}

export const ButtonAddCompany = ({
  onAccept,
  onDecline,
  open,
  loading = false,
}: IAddCompanyProps): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const [imageSrc, setImageSrc] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [changeImage, setChangeImage] = useState<File | null>(null);

  const selectDataState = useRef(null);
  const selectDataIndustry = useRef(null);

  const createCompany = useForm<InsertCompanySchema>({
    resolver: zodResolver(insertCompanySchema),
    defaultValues: {
      employeeCount: 0,
    },
  });

  const {
    reset,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = createCompany;

  const values = watch();

  const handleStateSelectChange = (value: string) => {
    setValue('state', value);
  };

  const handleIndustrySelectChange = (value: string) => {
    setValue('industry', value);
  };

  const handleManagerIdSelectChange = (value: string) => {
    setValue('managerId', value);
  };

  const handleConsultantIdSelectChange = (value: string) => {
    setValue('consultantId', value);
  };

  const resetForm = useCallback(() => {
    reset();
    setChangeImage(null);
    setImageSrc('');
    fileInputRef.current && (fileInputRef.current.value = '');
  }, [reset]);

  const handleDeclineAddCompany = useCallback(() => {
    if (!isSelectOpen.current.size) {
      onDecline?.();
      resetForm();
    }
  }, [onDecline, resetForm]);

  const handleRemoveImage = useCallback(() => {
    setChangeImage(null);
    setImageSrc('');
    fileInputRef.current && (fileInputRef.current.value = '');
  }, []);

  const handleChangeImage = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.files?.length === 0) {
        handleRemoveImage();
      }

      const newFile = target.files?.item(0);
      if (newFile) {
        setChangeImage(newFile);
        setImageSrc(URL.createObjectURL(newFile));
        setValue('logoImage', newFile);
      }
    },
    [handleRemoveImage, setValue]
  );

  useEffect(() => {
    if (!loading) {
      resetForm();
    }
  }, [loading]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const onSubmit: SubmitHandler<InsertCompanySchema> = (
    data: InsertCompanySchema
  ) => {
    onAccept?.(data);
  };

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...createCompany}>
      <FormModal
        acceptText="Add Client"
        declineText="Cancel"
        open={open}
        dataCy="add-clientForm"
        mainText="New Client"
        onOpenChange={handleDeclineAddCompany}
        onDecline={handleDeclineAddCompany}
        onAccept={handleSubmit(onSubmit)}
        loading={loading}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <FormBase.InputContent
            filled={!!values?.name}
            inputRef="name"
            label="Client Name"
            errorMessage={errors.name?.message}
            dataCy="label-name"
          >
            <FormBase.InputText
              dataCy="input-name"
              id="name"
              type="text"
              name="name"
            />
          </FormBase.InputContent>

          <ImageSection>
            <ImageContainer
              data-cy="client-imageContainer"
              hasImage={!!changeImage}
            >
              {changeImage && (
                <DeleteButton
                  data-cy="button-removeImage"
                  onClick={handleRemoveImage}
                >
                  <DeleteIcon />
                </DeleteButton>
              )}
              <LazyImage
                src={imageSrc}
                alt="client-image"
                dataCy="client-image"
              />
            </ImageContainer>
            <UploadFieldCustom
              dataCy="logo-fileInput"
              label="Logo"
              accept="image/*"
              onChange={handleChangeImage}
              ref={fileInputRef}
            />
          </ImageSection>

          <FormBase.InputContent
            filled={!!values?.employeeCount}
            inputRef="employees"
            label="# of employees"
            errorMessage={errors.employeeCount?.message}
            dataCy="label-# of employees"
          >
            <FormBase.InputText
              dataCy="input-employees"
              id="employees"
              name="employeeCount"
              type="number"
              max={999999}
              min={1}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.websiteUrl}
            inputRef="websiteUrl"
            label="Website URL"
            errorMessage={errors.websiteUrl?.message}
            dataCy="label-website"
          >
            <FormBase.InputText
              dataCy="input-website"
              id="websiteUrl"
              name="websiteUrl"
              type="text"
            />
          </FormBase.InputContent>
          <FormBase.InputContent
            filled={!!values.state}
            inputRef="select-state"
            label="State"
            errorMessage={errors.state?.message}
            dataCy="select-state"
            isSelectData
          >
            <SelectData
              data={states}
              value={values.state}
              dataCy={values.state}
              onValueChange={handleStateSelectChange}
              zIndex={2}
              ref={selectDataState}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'state');
              }}
              placeholder="State"
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.industry}
            inputRef="select-industry"
            label="Industry"
            errorMessage={errors.industry?.message}
            dataCy="select-industry"
            isSelectData
          >
            <SelectData
              data={industries}
              value={values.industry}
              dataCy={values.industry}
              onValueChange={handleIndustrySelectChange}
              zIndex={2}
              ref={selectDataIndustry}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'industry');
              }}
              placeholder="Industry"
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.managerId}
            inputRef="select-managerId"
            label="Manager"
            errorMessage={errors.managerId?.message}
            dataCy="select-managerId"
            isSelectData
          >
            <SelectContainer>
              <SelectManagers
                onValueChange={handleManagerIdSelectChange}
                dataCy={String(values.managerId)}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'managerId');
                }}
              />
            </SelectContainer>
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.consultantId}
            inputRef="select-consultantId"
            label="Consultant/Broker"
            errorMessage={errors.consultantId?.message}
            dataCy="select-consultantId"
            isSelectData
          >
            <SelectContainer>
              <SelectManagers
                onValueChange={handleConsultantIdSelectChange}
                dataCy={String(values.consultantId)}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'consultantId');
                }}
              />
            </SelectContainer>
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
