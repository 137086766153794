import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Button, SearchBar, useToast } from '@gbs-monorepo-packages/common';

import { ButtonAddCompany } from '../../components/ButtonAddCompany';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { useCompanies } from '../../hooks/useCompanies';
import type { IApiThrowsError, IPaginationMetaProps } from '../../services/api';
import {
  type IAddCompanyDTO,
  type ICompanyDTO,
  type IPaginationCompaniesDTO,
  getCompanies,
} from '../../services/companies';
import Logger from '../../utils/logger';
import {
  ButtonUrl,
  ButtonsContent,
  CompanyCard,
  CompanyName,
  Header,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
} from './styles';

export interface ISelectCompaniesProps {
  onValueChange?: (value: string) => void;
}

export const Companies = (): JSX.Element => {
  const [modalAddCompanyOpen, setModalAddCompanyOpen] = useState(false);
  const [loadingAddCompanies, setLoadingAddCompanies] = useState(false);
  const { addCompany, deleteCompany } = useCompanies();
  const { addToast } = useToast();
  const [companyToDelete, setCompanyToDelete] = useState<ICompanyDTO | null>(
    null
  );
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const isModalDeleteOpen = !!companyToDelete;

  const [companiesAux, setCompaniesAux] = useState<ICompanyDTO[]>([]);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const getCompaniesAux = useCallback(
    (page: number, limit: number) => {
      setLoadingCompanies(true);
      getCompanies({ page, limit, filter: search })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(response.data);
          setPaginationMeta(response.meta);

          if (modalAddCompanyOpen) {
            setModalAddCompanyOpen(false);
            setLoadingAddCompanies(false);

            addToast({
              title: 'Success',
              description: `Client was successfully created`,
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    },
    [search, addToast, modalAddCompanyOpen]
  );

  const searchCompanies = (searchByButton = false) => {
    if (!loadingCompanies || searchByButton) {
      setLoadingCompanies(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getCompanies({
        page: pageAux + 1,
        limit: 12,
        filter: search,
      })
        .then((response: IPaginationCompaniesDTO) => {
          setCompaniesAux(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingCompanies(false);
        });
    }
  };

  const handleDeclineDelete = useCallback(() => {
    setCompanyToDelete(null);
  }, []);

  const handleOpenModalDelete = (company: ICompanyDTO) => {
    setCompanyToDelete(company);
  };

  const handleAcceptDelete = useCallback(() => {
    if (companyToDelete && !loadingDelete) {
      setLoadingDelete(true);
      void deleteCompany(companyToDelete.id)
        .then(() => {
          getCompaniesAux(1, 12);
          addToast({
            title: 'Client deleted',
            description: `Client ${companyToDelete.name} was successfully deleted.`,
            styleType: 'success',
            dataCy: 'delete-client-success-toast',
            duration: 3000,
          });
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting client',
            description:
              'An error occurred. Please try again or contact Platform support.',
            styleType: 'error',
            dataCy: 'delete-client-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setLoadingDelete(false);
          setCompanyToDelete(null);
        });
    }
  }, [
    companyToDelete,
    loadingDelete,
    deleteCompany,
    getCompaniesAux,
    addToast,
  ]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setCompaniesAux([]);
        searchCompanies(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    if (!companiesAux.length) {
      setLoadingCompanies(true);
      getCompaniesAux(1, 12);
    }
  }, []);

  const handleAddCompany = useCallback(
    (data: IAddCompanyDTO) => {
      setLoadingAddCompanies(true);
      void addCompany(data)
        .then(() => {
          getCompaniesAux(1, 12);
        })
        .catch(() => {
          setLoadingAddCompanies(false);
          addToast({
            title: 'Error on creating client',
            description:
              'An error occurred. Please try again or contact Benefit Education support.',
            styleType: 'error',
            dataCy: 'add-client-error-toast',
          });
        });
    },
    [addToast, getCompaniesAux]
  );

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Clients</TitlePage>
        <Button
          dataCy="button-addClient"
          onClick={() => {
            setModalAddCompanyOpen(true);
          }}
          disabled={loadingCompanies}
          type="button"
        >
          Add Client
        </Button>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          search={search}
          placeholder="Search client"
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingCompanies}
        />

        <ContentPagination dataCy="content-companies">
          {loadingCompanies && companiesAux.length === 0 ? (
            <LoadingContainer data-cy="loading-companies-container">
              <Loading dataCy="loading-companies" />
            </LoadingContainer>
          ) : companiesAux.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={companiesAux.length} // This is important field to render the next data
              next={searchCompanies}
              hasMore={companiesAux.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {companiesAux.map((company) => (
                <CompanyCard key={company.id} data-cy={company.id}>
                  <CompanyName data-cy={company.name}>
                    {company.name}
                  </CompanyName>
                  <ButtonsContent data-cy="info-container">
                    <ButtonUrl
                      data-cy={`buttonCourses-client${company.id}`}
                      to={`/companies/${company.id}/courses`}
                    >
                      Courses
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonDocuments-client${company.id}`}
                      to={`/companies/${company.id}/documents`}
                    >
                      Documents
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonUsers-client${company.id}`}
                      to={`/companies/${company.id}/members`}
                    >
                      Users
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonSettings-client${company.id}`}
                      to={`/companies/${company.id}/settings`}
                    >
                      Client Settings
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonTemplates-client${company.id}`}
                      to={`/companies/${company.id}/templates`}
                    >
                      Templates
                    </ButtonUrl>
                    <ButtonUrl
                      data-cy={`buttonAnalytics-client${company.id}`}
                      to={`/companies/${company.id}/analytics`}
                    >
                      Analytics
                    </ButtonUrl>
                    <Button
                      data-cy={`buttonDelete-client${company.id}`}
                      type="button"
                      onClick={() => {
                        handleOpenModalDelete(company);
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonsContent>
                </CompanyCard>
              ))}
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No clients found"
              dataCy="text-noClients-found"
            />
          )}
        </ContentPagination>
      </MainContent>
      <DialogModal
        dataCy="dialog-deleteClient"
        acceptText="Confirm"
        declineText="Cancel"
        open={isModalDeleteOpen}
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          companyToDelete?.name ?? 'this company'
        }?`}
        onAccept={handleAcceptDelete}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
      />

      <ButtonAddCompany
        data-cy="button-addClient"
        onAccept={handleAddCompany}
        onDecline={() => {
          setModalAddCompanyOpen(false);
        }}
        open={modalAddCompanyOpen}
        loading={loadingAddCompanies}
      />
    </MainContainer>
  );
};
